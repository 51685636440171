import React, { useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { EnumDollhouseStatus, IPropsTourCards } from './interface';
import { formatBytes } from '../bytes-converter/bytes-converter';
import { ReactComponent as MenuDots } from '../../../images/dashboard-icons/ic-menu-dots-inactive.svg';
import { ReactComponent as MenuDotsActive } from '../../../images/dashboard-icons/card-icons/btn-hover.svg';
import defaultImage from '../../../images/empty.svg';
import passwordLock from '../../../images/dashboard-icons/password-lock.svg';
import leadsGeneration from '../../../images/dashboard-icons/lead-generation.svg';
import displayLocation from '../../../images/dashboard-icons/display-location.svg';
import imageIconCard from '../../../images/dashboard-icons/card-icons/icon-img-card.svg';
import linkIconCard from '../../../images/dashboard-icons/card-icons/link-icon-card.svg';
import locationIcon from '../../../images/dashboard-icons/card-icons/icon-location.svg';
import viewsIconCard from '../../../images/dashboard-icons/card-icons/icon-views.svg';
import timeIconCard from '../../../images/dashboard-icons/card-icons/icon-img-time.svg';
import profileIcon from '../../../images/dashboard-icons/profile-icon.svg';
import dollHouse from '../../../images/dashboard-icons/dollhouse-icon.svg';
import floorPlan from '../../../images/dashboard-icons/floorplan-default-hover.svg';
import './tour-views-dropdown-menu.scss';
import './tour-rows.scss';
import './tour-rows-new.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ITours } from '../../../api-helper/interface/interfaces';
import { linguiTranslateToString } from '../lingui-utils/utils-lingui';

const TourRowsUsers: React.FC<IPropsTourCards> = ({
  page,
  keyword,
  tours,
  toursRef,
  showModal,
  archiveTours,
  duplicateATour,
  orderOptionName,
  restoreTours,
  type,
  orderOption,
  connectToUserGoToStudio,
  downloadAllPanoramas,
  showDollhouseTab,
  apiNoPayment,
  downloadFloorplans,
}) => {
  const [onHoverDisplay, setOnHoverDisplay] = useState<string>('');
  const [activeMenu, setActiveMenu] = useState<boolean>(false);

  const getStandaloneViewerUrl = (tourReadCode: string, short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + tourReadCode;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && tourReadCode ? shortUrl + tourReadCode : '';
    } else {
      return url ? url : '';
    }
  };

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = defaultImage;
  };

  return (
    <React.Fragment>
      {tours?.map((tour: ITours, index: number) => (
        <div id='tours-row' ref={element => (toursRef.current[index] = element)} key={index} className='tour-row'>
          <div
            className='row-image-doll'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourProfile);
            }}
          >
            {!restoreTours && (
              <div className='lock-leads-icons'>
                {tour.protected && (
                  <button style={{ cursor: 'default' }} className=' icon-on-card'>
                    <img title={linguiTranslateToString('Password protected')} src={passwordLock} alt='Padlock password protect' />
                  </button>
                )}
                {tour.shareLocation === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Location displayed')} src={displayLocation} alt='Location displayed' />
                  </button>
                )}
                {tour.enableLeadGenerationForm === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Leads generation')} src={leadsGeneration} alt='Leads generation' />
                  </button>
                )}
              </div>
            )}
            <div className='edit-link'>
              <Link
                to={{
                  pathname: '/edit-tour/panoramas',
                  search: `?tourId=${tour.id}`,
                  state: {
                    ...tour,
                    paginationPage: page,
                    searchWord: keyword,
                    tourIndex: index,
                    displayDashboard: true,
                    orderOptionName: orderOptionName,
                    orderOption: orderOption,
                    dashboardView: 'rows',
                  },
                }}
              >
                <div className='img-icon-card'></div>
                <Trans id='Tour details' />
              </Link>
            </div>
            <img className='img-row-view' onError={addDefaultSrc} src={tour.mainPhotoUrl || defaultImage} alt='Tour preview' />
          </div>
          <div className='info-row-view-doll'>
            <div className='container-title-info'>
              <div id='tour-name' className='tour-row-address'>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{ pathname: '/edit-tour/panoramas', search: `?tourId=${tour.id}`, state: tour }}
                >
                  {tour.name}
                </Link>
              </div>
              <div className='container-info-tour'>
                <div className='card-sub-info' style={{ paddingLeft: '0' }}>
                  <img className='img-icon' src={imageIconCard} alt='Tour number of images icon' />

                  <Trans
                    id='{numImages} images ({tourSize})'
                    values={{ numImages: tour.panoramaCount, tourSize: formatBytes(tour.tourSize || 0) }}
                    render={({ translation }) => <p className='text-sub-info'>{translation}</p>}
                  />
                </div>
                <div className='card-sub-info' style={{ borderRight: '0.5px solid #757575', borderLeft: '0.5px solid #757575' }}>
                  <img className='time-icon' src={timeIconCard} alt='last updated' />
                  <span className='text-sub-info'>
                    <Trans
                      id=' <0>{updatedTour}</0> at <1>{updatedTour}</1>'
                      values={{ updatedTour: tour.updatedAt }}
                      components={{ 0: <Moment format='DD/MM/YY'></Moment>, 1: <Moment format='HH:mm'></Moment> }}
                    />
                  </span>
                </div>
                <div className='card-sub-info'>
                  <img className='view-icon' src={viewsIconCard} alt='Tour number of views icon' />
                  <Trans
                    id='{numViews} views'
                    values={{ numViews: tour.views }}
                    render={({ translation }) => <p className='text-sub-info'>{translation}</p>}
                  />
                </div>
              </div>
            </div>
            <div className='container-bottom-info'>
              <div className='container-left-info'>
                <div className='info-details-location'>
                  <img className='details-icons' src={locationIcon} alt='Tour Location icon' />
                  <p title={tour.name} className='text-info-bottom-doll'>
                    {tour.name}
                  </p>
                </div>
                <div
                  className='info-url-users'
                  onClick={() => {
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourWithUrl);
                  }}
                >
                  <img className='details-icons' src={linkIconCard} alt='Tour url icon' />
                  {type === 'archived' || type === 'deleted' ? (
                    <p className='text-info-bottom-doll invalid-link'>{getStandaloneViewerUrl(tour.readCode, false)}</p>
                  ) : (
                    <a
                      title={getStandaloneViewerUrl(tour.readCode, false)}
                      className='text-info-bottom-doll'
                      href={getStandaloneViewerUrl(tour.readCode, false)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {getStandaloneViewerUrl(tour.readCode, false)}
                    </a>
                  )}
                </div>
              </div>
              <div className='container-right-info'>
                <div className='info-email-detail'>
                  <img className='details-icons' src={profileIcon} alt='Tour Location icon' />
                  <p className='text-info-bottom-doll'> {tour.userEmail} </p>
                </div>
              </div>
            </div>
          </div>
          <div className='btns-row-view'>
            <div className={'three-dot-options'} style={{ height: '35%' }}>
              <div className={onHoverDisplay === tour.id ? 'cirlce-container-focus' : 'circle-container-dot'}>
                <div className='dots-dropdown-parent-row'>
                  <div className='dots-dropdown'>
                    <button
                      className='remove-default'
                      onClick={() => {
                        setActiveMenu(true);
                        setOnHoverDisplay(tour.id);
                      }}
                      onMouseLeave={() => setActiveMenu(false)}
                    >
                      <div id='dots-menu' className='dots-btn'>
                        {onHoverDisplay === tour.id ? <MenuDotsActive /> : <MenuDots />}
                      </div>
                    </button>
                    <div
                      onClick={() => {
                        setActiveMenu(!activeMenu);
                        setOnHoverDisplay(tour.id);
                      }}
                      onMouseLeave={() => setOnHoverDisplay('')}
                      className={`dots-dropdown-content ${onHoverDisplay === tour.id ? 'dots-dropdown-content-active' : ''}`}
                    >
                      <div className='dots-dropdown-menu'>
                        <span>
                          <button
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.ShareTour);
                              const modalContent = {
                                id: 'share-tour',
                                title: linguiTranslateToString('Share your virtual tour'),
                                tour: tour,
                              };
                              showModal(modalContent);
                            }}
                            className='remove-default dots-options'
                          >
                            <div className='icon-share-dropdown' />
                            <Trans id='Share tour' render={({ translation }) => <div className='dots-options-text'>{translation}</div>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              if (duplicateATour) {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.DuplicateTour);
                                duplicateATour(tour.id);
                              }
                            }}
                          >
                            <div className='icon-duplicate-dropdown' />
                            <Trans id='Duplicate tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.TransferTour);
                              const modalContent = {
                                id: 'move-tour',
                                title: linguiTranslateToString('Transfer tour to a different account'),
                                tourId: tour.id,
                                tourName: tour.name,
                                userEmail: tour.userEmail,
                              };
                              showModal(modalContent);
                            }}
                          >
                            <div className='icon-transfer-dropdown' />
                            <Trans id='Transfer tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.DownloadEntireTour);
                              if (downloadAllPanoramas) {
                                downloadAllPanoramas(tour.id, tour.name);
                              }
                            }}
                          >
                            <div className='icon-download-dropdown' />
                            <Trans id='Download tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          {!tour.protected ? (
                            <button
                              className='remove-default dots-options'
                              id='download-dashboard'
                              onClick={() => {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.AddPasswordToTour);
                                const modalContent = {
                                  id: 'password-protect',
                                  title: linguiTranslateToString('Protect with password'),
                                  tourId: tour.id ? tour.id : '',
                                  tourName: tour.name ? tour.name : '',
                                };
                                showModal(modalContent);
                              }}
                            >
                              <div className='icon-lock-dropdown' />
                              <Trans
                                id='Password protect'
                                render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                              />
                            </button>
                          ) : (
                            <button
                              className='remove-default dots-options'
                              onClick={() => {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.RemovePasswordToTour);
                                const modalContent = {
                                  id: 'remove-password-protect',
                                  title: linguiTranslateToString('Remove password'),
                                  tourId: tour.id ? tour.id : '',
                                  tourName: tour.name ? tour.name : '',
                                };
                                showModal(modalContent);
                              }}
                            >
                              <div className='icon-open-lock-dropdown' />
                              <Trans
                                id='Remove password'
                                render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                              />
                            </button>
                          )}
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              if (archiveTours) {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.ArchiveTour);
                                archiveTours(tour.id, true);
                              }
                            }}
                          >
                            <div className='icon-archive-dropdown' />
                            <Trans id='Archive tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.DeleteTour);
                              const modalContent = {
                                id: 'delete-tour',
                                title: linguiTranslateToString('Delete tour'),
                                tourId: tour.id ? tour.id : '',
                                tourName: tour.name ? tour.name : '',
                              };
                              showModal(modalContent);
                            }}
                          >
                            <div className='icon-delete-dropdown' />
                            <Trans id='Delete tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='account-studio-container'>
              <button
                id='connectGoToStudio'
                className='connect-goto-studio'
                onClick={() => {
                  if (connectToUserGoToStudio) {
                    ReportCustomEvent(EnumCategory.ConnectToTour, EnumEvent.ConnectToTourButton);
                    connectToUserGoToStudio(tour.userId, tour.tourId ? tour.tourId : tour.id);
                  }
                }}
              >
                <div className='go-to-account-icon'></div>
                <Trans id='Account’s studio' />
              </button>
            </div>
            {showDollhouseTab && (
              <>
                {tour?.dollhouseStatus !== undefined && tour?.dollhouseStatus === EnumDollhouseStatus.FloorplanSent ? (
                  <button
                    className='ordered-dollhouse-btn'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (downloadFloorplans) {
                        downloadFloorplans(tour.id, tour.readCode);
                      }
                    }}
                  >
                    <div className='icons-doll-floor'>
                      <img src={floorPlan} alt='floorPlan' className='icon-button-card' />
                      <img src={dollHouse} alt='dollhouse' className='icon-button-card' />
                    </div>
                    <Trans id='Download Floorplan' />
                  </button>
                ) : tour?.dollhouseStatus !== null && tour.dollhouseStatus !== undefined && tour.dollhouseStatus >= 0 ? (
                  <div className='ordered-dollhouse-btn'>
                    <div className='icons-doll-floor'>
                      <img src={floorPlan} alt='floorPlan' className='icon-button-card' />
                      <img src={dollHouse} alt='dollhouse' className='icon-button-card' />
                    </div>
                    <Trans id='Floor Plan & Dollhouse Ordered' />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      const modalContent = {
                        id: 'order-dollhouse',
                        title: linguiTranslateToString('Order Dollhouse & Floor Plan'),
                        tourName: tour.name ? tour.name : '',
                        tourId: tour.id ? tour.id : '',
                        backUrl: 'live-tours',
                        panoramaCount: tour.panoramaCount,
                        apiNoPayment: apiNoPayment,
                      };
                      showModal(modalContent);
                      ReportCustomEvent(EnumCategory.Dollhouse, EnumEvent.OrderDollhouse);
                    }}
                    className='order-dollhouse-btn'
                  >
                    <div className='button-element'>
                      <img src={floorPlan} alt='floorPlan' className='icon-button-card' />
                      <Trans id='Order Floor Plan' />
                    </div>
                    <div className='button-element'>
                      <img src={dollHouse} alt='dollhouse' className='icon-button-card' />
                      <Trans id='& Dollhouse' />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default TourRowsUsers;
